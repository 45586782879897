import { createBrowserRouter } from "react-router-dom";
import Login from "./auth/login";
import Dashboard from "./dashboard";
import Colors from "./colors";
import CreateColor, {
  action as createColorAction,
} from "./colors/components/create-color";
import UpdateColor, {
  action as updateColorAction,
  loader as updateColorLoader,
} from "./colors/components/update-color";
import Categories from "./categories";
import { generateGetLoader } from "./lib/helpers";
import CreateCategory, {
  action as createCategoryAction,
} from "./categories/components/create-category";
import UpdateCategory, {
  action as updateCategoryAction,
  loader as updateCategoryLoader,
} from "./categories/components/update-category";
import Suppliers from "./suppliers";
import CreateSupplier, {
  action as createSupplierAction,
} from "./suppliers/components/create-supplier";
import UpdateSupplier, {
  action as updateSupplierAction,
  loader as updateSupplierLoader,
} from "./suppliers/components/update-supplier";
import Items from "./items";
import CreateItem, {
  action as createItemAction,
  loader as createItemLoader,
} from "./items/components/create-item";
import { loader as itemsLoader, action as itemStatusAction } from "./items";
import Detail, {
  loader as itemLoader,
  action as itemAction,
} from "./items/components/detail";
import Customers, { loader as customersLoader } from "./customers";
import CustomerDetail, {
  loader as customerDetailsLoader,
  action as customerDetailsAction,
} from "./customers/components/customer-detail";
import Industries from "./industries";
import CreateIndustry, {
  action as createIndustryAction,
} from "./industries/components/create-industry";
import UpdateIndustry, {
  action as updateIndustryAction,
  loader as updateIndustryLoader,
} from "./industries/components/update-industry";
import ErrorPage from "./errors/error";
import NotFoundPage from "./errors/not-found";
import Orders, { loader as ordersLoader } from "./orders";
import OrderDetails, {
  loader as orderDetailsLoader,
  action as orderDetailsAction,
} from "./orders/components/order-details";
import CreatePO, {
  action as createPOAction,
} from "./purchase-orders/components/create-po";
import PurchaseOrders, {
  loader as purchaseOrdersLoader,
} from "./purchase-orders";
import Invoices, { loader as invoicesLoader } from "./invoices";
import InvoiceDetails, {
  loader as invoiceDetailsLoader,
  action as invoiceDetailsAction,
} from "./invoices/components/Details";
import PurchaseOrderDetails, {
  loader as poLoader,
  action as poAction,
} from "./purchase-orders/components/details";
import ColourCategories, {
  loader as colourCategoriesLoader,
} from "./colour-categories";
import CreateColourCategory, {
  action as createCCAction,
} from "./colour-categories/components/create-colour-category";
import Flashings from "./flashings-shape-library";
import CreateShape, {
  action as createShapeAction,
} from "./flashings-shape-library/components/create-shape";
import UpdateShape, {
  loader as updateShapeLoader,
  action as updateShapeAction,
} from "./flashings-shape-library/components/update-shape";
import FlashingsPricing from "./flashing-pricing";
import CreatePricing, {
  loader as createPricingLoader,
} from "./flashing-pricing/components/create-pricing";
import { action as sendPoAction } from "./purchase-orders/components/send-po";
import { action as updatePoStatusAction } from "./purchase-orders/components/update-po-status";
import { wrapCreateBrowserRouter } from "@sentry/react";
import AccessoriesConfirm, {
  loader as accessoriesConfirmLoader,
} from "./items/components/accessories/confirm";
import Accessories, {
  loader as accessoriesLoader,
  action as accessoriesAction,
} from "./items/components/accessories";

const router = wrapCreateBrowserRouter(createBrowserRouter)([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/user",
    element: <Dashboard />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "colours",
        element: <Colors />,
        loader: generateGetLoader(
          `${process.env.REACT_APP_BASE_URL}/api/colour/getAll`,
          "colors",
        ),
        children: [
          {
            path: "add",
            element: <CreateColor />,
            action: createColorAction,
            loader: generateGetLoader(
              `${process.env.REACT_APP_BASE_URL}/api/colourCategory/getMany?limit=50&page=1`,
              "colourCategories",
            ),
          },
          {
            path: ":id",
            element: <UpdateColor />,
            action: updateColorAction,
            loader: updateColorLoader,
          },
        ],
      },
      {
        path: "categories",
        element: <Categories />,
        loader: generateGetLoader(
          `${process.env.REACT_APP_BASE_URL}/api/category/getMany`,
          "categories",
        ),
        children: [
          {
            path: "add",
            element: <CreateCategory />,
            action: createCategoryAction,
          },
          {
            path: ":id",
            element: <UpdateCategory />,
            action: updateCategoryAction,
            loader: updateCategoryLoader,
          },
        ],
      },
      {
        path: "industries",
        element: <Industries />,
        loader: generateGetLoader(
          `${process.env.REACT_APP_BASE_URL}/api/industry/getMany`,
          "industries",
        ),
        children: [
          {
            path: "add",
            element: <CreateIndustry />,
            action: createIndustryAction,
            loader: generateGetLoader(
              `${process.env.REACT_APP_BASE_URL}/api/category/getMany`,
              "categories",
            ),
          },
          {
            path: ":id",
            element: <UpdateIndustry />,
            action: updateIndustryAction,
            loader: updateIndustryLoader,
          },
        ],
      },
      {
        path: "suppliers",
        element: <Suppliers />,
        loader: generateGetLoader(
          `${process.env.REACT_APP_BASE_URL}/api/supplier/getAll`,
          "suppliers",
        ),
      },
      {
        path: "suppliers/add",
        element: <CreateSupplier />,
        action: createSupplierAction,
      },
      {
        path: "suppliers/:id",
        element: <UpdateSupplier />,
        action: updateSupplierAction,
        loader: updateSupplierLoader,
      },
      {
        path: "items",
        element: <Items />,
        loader: itemsLoader,
        action: itemStatusAction,
      },
      {
        path: "items/add",
        element: <CreateItem />,
        loader: createItemLoader,
        action: createItemAction,
      },
      {
        path: "items/:id/accessories",
        element: <Accessories />,
        loader: accessoriesLoader,
        action: accessoriesAction,
      },
      {
        path: "items/:id/accessories/confirm",
        element: <AccessoriesConfirm />,
        loader: accessoriesConfirmLoader,
      },
      {
        path: "items/:id",
        element: <Detail />,
        loader: itemLoader,
        action: itemAction,
        // children: [
        //   {
        //     path: "variants/:variantId",
        //     element: <VariantDetail />,
        //     loader: variantLoader,
        //     action: variantAction,
        //   },
        // ],
      },
      {
        path: "orders",
        element: <Orders />,
        loader: ordersLoader,
      },
      {
        path: "orders/:id",
        element: <OrderDetails />,
        loader: orderDetailsLoader,
        action: orderDetailsAction,
      },
      {
        path: "purchase-orders",
        loader: purchaseOrdersLoader,
        element: <PurchaseOrders />,
      },
      {
        path: "purchase-orders/add",
        action: createPOAction,
        element: <CreatePO />,
        loader: generateGetLoader(
          [
            `${process.env.REACT_APP_BASE_URL}/api/colourCategory/getMany?limit=50&page=1`,
            `${process.env.REACT_APP_BASE_URL}/api/supplier/getAll`,
          ],
          ["colourCategories", "suppliers"],
        ),
      },
      {
        path: "purchase-orders/send",
        action: sendPoAction,
      },
      {
        path: "purchase-orders/status",
        action: updatePoStatusAction,
      },
      {
        path: "purchase-orders/:id",
        loader: poLoader,
        element: <PurchaseOrderDetails />,
        action: poAction,
      },
      {
        path: "invoices",
        loader: invoicesLoader,
        element: <Invoices />,
      },
      {
        path: "invoices/:id",
        loader: invoiceDetailsLoader,
        action: invoiceDetailsAction,
        element: <InvoiceDetails />,
      },
      {
        path: "colour-categories",
        loader: colourCategoriesLoader,
        element: <ColourCategories />,
        children: [
          {
            path: "add",
            element: <CreateColourCategory />,
            action: createCCAction,
          },
        ],
      },
      {
        path: "flashings-shape-library",
        element: <Flashings />,
        loader: generateGetLoader(
          `${process.env.REACT_APP_BASE_URL}/api/flashingShape/getMany`,
          "shapes",
        ),
        children: [
          {
            path: "add",
            element: <CreateShape />,
            action: createShapeAction,
          },
          {
            path: ":id",
            element: <UpdateShape />,
            loader: updateShapeLoader,
            action: updateShapeAction,
          },
        ],
      },
      {
        path: "flashings-pricing",
        element: <FlashingsPricing />,
        loader: generateGetLoader(
          `${process.env.REACT_APP_BASE_URL}/api/flashingBranchItems/admin/getAll`,
          "branchItems",
        ),
      },
      {
        path: "flashings-pricing/add",
        element: <CreatePricing />,
        loader: createPricingLoader,
      },
      {
        path: "customers",
        element: <Customers />,
        loader: customersLoader,
        // children: [
        //   {
        //     path: "add",
        //     element: <CreateCustomer />,
        //     action: createCustomerAction,
        //   },
        // ],
      },
      {
        path: "customers/:id",
        element: <CustomerDetail />,
        loader: customerDetailsLoader,
        action: customerDetailsAction,
      },
    ],
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);

export default router;
