import { useFetcher, useLoaderData } from "react-router-dom";
import { generateGetLoader } from "../../../lib/helpers";
import BreadCrums from "../../../components/breadcrums";
import MutliSelect from "../../../components/multi-select";
import styles from "./accessory.module.css";
import PrimaryButton from "../../../components/primary-button";
import Autocomplete from "../../../components/autocomplete";
import React from "react";
import { createGetRequest, createPostRequest } from "../../../lib/network";
import Dropdown from "../../../components/dropdown";
import Table from "../../../components/table";

export async function loader({ params }) {
  const data = await generateGetLoader(
    [
      `${process.env.REACT_APP_BASE_URL}/api/item/get/${params.id}`,
      `${process.env.REACT_APP_BASE_URL}/api/accessories/getForItem/${params.id}`,
    ],
    ["item", "accessories"],
  )();
  return data;
}

export async function action({ request, params }) {
  const formData = await request.formData();
  const data = Object.fromEntries(formData);
  const itemId = params.id;
  if (data.action === "add") {
    const promises = [];
    const variants = data["Variant_Size"].split(",");
    const { Variant_Size, action, ...rest } = data;
    for (const variant of variants) {
      promises.push(
        createPostRequest(
          `${process.env.REACT_APP_BASE_URL}/api/accessories/create`,
          {
            ItemID: itemId,
            Variant_Size_ID: variant,
            ...rest,
          },
        ),
      );
    }
    await Promise.all(promises);
  } else {
    await createPostRequest(
      `${process.env.REACT_APP_BASE_URL}/api/accessories/delete`,
      {
        DeleteAccessoryID: data.DeleteAccessoryID,
      },
    );
  }
  return null;
}

function formatter(response) {
  if (response.data.items.length > 0) {
    return response.data.items.map((record) => ({
      id: record._id,
      label: record.Item_Name,
    }));
  } else {
    return [];
  }
}

function getVariantSizes(item) {
  return Object.values(
    item.Variants.reduce((acc, curr) => {
      if (!acc[curr.Variant_Size_ID]) {
        acc[curr.Variant_Size_ID] = {
          label: curr.Variant_Size,
          value: curr.Variant_Size_ID,
        };
      }
      return acc;
    }, {}),
  );
}

const headers = [
  { name: "Item Variant", width: "25%" },
  {
    width: "75%",
    name: (
      <div className={styles.gridContainer}>
        <div>Accessory Item</div>
        <div>Accessory Variant</div>
        <div />
      </div>
    ),
  },
];

export default function Accessories() {
  const { item, accessories } = useLoaderData();
  const fetcher = useFetcher();
  const variantSizes = getVariantSizes(item);
  const accessoryRows = Object.values(
    accessories.reduce((acc, curr) => {
      if (!acc[curr.Variant_Size_ID]) {
        acc[curr.Variant_Size_ID] = [[curr.VariantID.Variant_Size]];
      }
      acc[curr.Variant_Size_ID].push({
        accessory: curr.AccessoryID.Item_Name,
        accessoryVariant: curr.AccessoryVariantID?.Variant_Size || "-",
        id: curr._id,
      });
      return acc;
    }, {}),
  ).map((value) => {
    const [variant, ...rest] = value;
    return [
      {
        value: variant,
      },
      {
        value: (
          <>
            {rest.map((record, index) => (
              <div
                key={record.id}
                className={`${styles.gridContainer} ${
                  index === 0 ? "" : styles.record
                }`}
              >
                <div>{record.accessory}</div>
                <div>{record.accessoryVariant}</div>
                <div className={styles.rightAligned}>
                  <button
                    className={styles.removeButton}
                    onClick={() => {
                      fetcher.submit(
                        {
                          action: "remove",
                          DeleteAccessoryID: record.id,
                        },
                        {
                          method: "POST",
                        },
                      );
                    }}
                    disabled={
                      fetcher.state === "submitting" ||
                      fetcher.state === "loading"
                    }
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
          </>
        ),
      },
    ];
  });

  accessoryRows.sort((a, b) => {
    return a[0].value > b[0].value ? 1 : -1;
  });

  return (
    <>
      <BreadCrums
        segments={[
          { label: "Items", link: "/user/items" },
          {
            label: item.Item_Name,
            link: `/user/items/${item._id}`,
          },
          { label: "Accessories", link: "." },
        ]}
      />
      <div className={styles.header}>Accessories for {item.Item_Name}</div>
      <AddAccessory variantSizes={variantSizes} fetcher={fetcher} />
      <div className={styles.subSection}>Added Items for {item.Item_Name}</div>
      <Table
        headers={headers}
        rows={accessoryRows}
        bodyStyles={{
          height: "auto",
          minHeight: 350,
        }}
      />
    </>
  );
}

function AddAccessory({ variantSizes, fetcher }) {
  const [loading, setLoading] = React.useState(false);
  const [item, setItem] = React.useState("");
  const [accessoryVariants, setAccessoryVariant] = React.useState([]);

  async function onItemChange(id) {
    const response = await createGetRequest(
      `${process.env.REACT_APP_BASE_URL}/api/item/get/${id}`,
    );
    if (!response.data) {
      alert("Something went wrong, please select accessory item again");
    } else {
      setItem(id);
      setAccessoryVariant(getVariantSizes(response.data));
    }
    setLoading(false);
  }

  function handleSubmit(e) {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const variants = formData.getAll("Variant_Size");
    if (variants.length === 0) {
      document
        .querySelector('[name="Variant_Size"]')
        .setCustomValidity("Please select at least one variant");
      form.reportValidity();
      return;
    } else {
      document.querySelector('[name="Variant_Size"]').setCustomValidity("");
    }
    const body = {
      action: "add",
      ...Object.fromEntries(formData),
      Variant_Size: variants,
    };
    fetcher.submit(body, {
      method: "POST",
    });
    form.reset();
    setItem("");
    setAccessoryVariant([]);
  }

  return (
    <form className={styles.addForm} onSubmit={handleSubmit} method="POST">
      <MutliSelect
        id="variant"
        label="Item Variant"
        name="Variant_Size"
        addAll
        options={variantSizes}
        placeholder="Select Variant"
        fullWidth
      />
      <Autocomplete
        id="accessory"
        label="Accessory Item"
        name="AccessoryID"
        placeholder="Select Accessory"
        fullWidth
        required
        url={`${process.env.REACT_APP_BASE_URL}/api/item/getAll?page=1&limit=10`}
        callback={onItemChange}
        disabled={loading}
        formatter={formatter}
      />
      <Dropdown
        id="accessory-variant"
        label="Accessory Variant"
        name="Accessory_Variant_Size_ID"
        options={accessoryVariants}
        placeholder="Select Accessory Variant"
        fullWidth
        key={item}
      />
      <div className={styles.addContainer}>
        <PrimaryButton
          label="Add"
          type="submit"
          fullWidth
          loading={
            fetcher.state === "loading" || fetcher.state === "submitting"
          }
        />
      </div>
    </form>
  );
}

function AccessoriesTable(rows) {
  return (
    <div>
      <table style={{ width: "100%" }}>
        <colgroup>
          <col style={{ width: "30%" }} />
          <col style={{ width: "30%" }} />
          <col style={{ width: "30%" }} />
          <col style={{ width: "10%" }} />
        </colgroup>
        <thead>
          <tr>
            <th>Item Variant</th>
            <th>Accessory Item</th>
            <th>Accessory Variant</th>
            <th></th>
          </tr>
        </thead>
      </table>
      <div style={{ height: "auto", minHeight: 350 }}>
        <table style={{ width: "100%" }}>
          <colgroup>
            <col style={{ width: "30%" }} />
            <col style={{ width: "30%" }} />
            <col style={{ width: "30%" }} />
            <col style={{ width: "10%" }} />
          </colgroup>
          <tbody>
            <tr>
              <td>0.42</td>
              <td colSpan={3}>
                <table style={{ width: "100%", fontSize: 40 }}>
                  <tbody>
                    <tr>
                      <td>Roll Top Ridge</td>
                      <td>0.42 (l/m)</td>
                      <td>Remove</td>
                    </tr>
                    <tr>
                      <td>60mm Anticon</td>
                      <td>
                        R1.3 Light Duty Facing 15m x 1200mm x 60mm (18 m2 Roll)
                      </td>
                      <td>Remove</td>
                    </tr>
                    <tr>
                      <td>60mm Anticon</td>
                      <td>Valley Gutter (Typical Domestic Application)</td>
                      <td>Remove</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
