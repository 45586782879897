import PrimaryButton from "../../../components/primary-button";
import styles from "./customer-detail.module.css";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import Control from "../../../components/control";
import Dropdown from "../../../components/dropdown";

export default function UpdateCustomer({
  setCustomerUpdate,
  customer,
  fetcher,
}) {
  function handleSubmit(e) {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    fetcher.submit(
      {
        action: "customer-update",
        ...Object.fromEntries(formData.entries()),
      },
      { method: "POST" },
    );
    setCustomerUpdate(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.modalHeader}>
        <span className={styles.modalHeading}>Update Customer</span>
        <Close onClick={() => setCustomerUpdate(false)} />
      </div>
      <div className={styles.modalContent}>
        <div className={styles.customerForm}>
          <Control
            name="Name"
            id="name"
            label="Name"
            required
            initialValue={
              customer.Customer_Business_Name || customer.Customer_Name
            }
            fullWidth
          />
          <Dropdown
            label="Account Type"
            id="account-type"
            name="Account_Type"
            required
            fullWidth
            value={customer.Customer_Type}
            options={[
              { label: "Individual", value: "Individual" },
              { label: "Business", value: "Business" },
            ]}
            placeholder="Select Type"
          />
          <Control
            name="Email"
            id="email"
            label="Email"
            fullWidth
            type="email"
            required
            initialValue={
              customer.Customer_Business_Email || customer.Customer_Email
            }
          />
          <Control
            name="Phone"
            id="phone"
            label="Phone"
            fullWidth
            type="tel"
            required
            initialValue={(
              customer.Customer_Business_Phone || customer.Customer_Phone
            ).slice(2)}
          />
        </div>
        <div className={styles.modalButtonContainer}>
          <PrimaryButton
            label="Update Customer"
            type="submit"
            loading={
              fetcher.state === "loading" || fetcher.state === "submitting"
            }
          />
        </div>
      </div>
    </form>
  );
}
