import { useLoaderData, useNavigate } from "react-router-dom";
import PageHeader from "../components/page-header";
import Table from "../components/table";
import { generateGetLoader, formatPrice } from "../lib/helpers";

export async function loader({ request }) {
  const url = new URL(request.url);
  const page = url.searchParams.get("page") || 1;
  const response = await generateGetLoader(
    `${process.env.REACT_APP_BASE_URL}/api/customer/admin/getMany?limit=30&offset=${page}`,
    "customers",
  )();
  return response;
}

export default function Customers() {
  const { customers } = useLoaderData();
  const navigate = useNavigate();
  const customerRows =
    customers.customers.map((customer) => [
      {
        value: customer._id,
      },
      { value: customer.Customer_Business_Name || customer.Customer_Name },
      { value: customer.Customer_Business_Phone || customer.Customer_Phone },
      { value: customer.Customer_Business_Email || customer.Customer_Email },
      { value: customer.Customer_Type },
      { value: formatPrice(customer.Credit_Limit_Amount || 0), align: "right" },
    ]) || [];
  const currentPage = customers.currentPage;
  const totalPages = customers.totalPages;

  return (
    <>
      <PageHeader text="Customers" />
      <Table
        headers={[
          { name: "Name", width: "20%" },
          { name: "Phone Number", width: "30%" },
          { name: "Email", width: "30%" },
          { name: "Type", width: "15%" },
          { name: "Credit Limit", width: "15%", align: "right" },
        ]}
        rows={customerRows}
        onRowClick={(id) => navigate(`/user/customers/${id}`)}
        nextLink={
          currentPage < totalPages ? `?page=${currentPage + 1}` : undefined
        }
        prevLink={currentPage > 1 ? `?page=${currentPage - 1}` : undefined}
        currentPage={currentPage}
        totalPages={totalPages}
      />
    </>
  );
}
