import React from "react";
import PrimaryButton from "../primary-button";
import styles from "./edit.module.css";
import Dropdown from "../dropdown";
import Autocomplete from "../autocomplete";
import { createGetRequest } from "../../lib/network";
import Control from "../control";
import DisplayDropdown from "../display-dropdown";
import _ from "lodash";
import CuttingList from "../cutting-list";

function formatter(response) {
  if (response.data.items.length > 0) {
    return response.data.items[0].items.map((record) => ({
      id: record._id,
      label: record.Name,
    }));
  } else {
    return [];
  }
}

export default function OrderEdit({
  editedRow,
  items,
  suppliers,
  onDiscard,
  onAdd,
  onEdit,
  onDelete,
  location,
  selectedDetails,
  colourCategories,
  cp,
}) {
  const item = editedRow ? items[parseInt(editedRow)] : undefined;
  const [editing, setEditing] = React.useState(false);
  const [supplier, setSupplier] = React.useState(
    item?.raw?.Branch_Item_Object.Branch_Item.Item_Supplier._id ||
      selectedDetails?.supplier,
  );
  const [loading, setLoading] = React.useState(false);
  const [itemDetails, setItemDetails] = React.useState({
    ...(item?.raw?.Branch_Item_Object || {}),
    variants: Object.entries(
      item?.raw?.Branch_Item_Object?.Branch_Item_Variants.reduce(
        (acc, curr) => {
          if (!acc[curr.Variant_Size]) {
            acc[curr.Variant_Size] = {
              colours: {},
              minSize: curr.Variant_Size_Min,
              maxSize: curr.Variant_Size_Max,
              type: curr.Variant_Size_Type,
              unit: curr.Variant_Size_Type_Units,
              branch: item?.raw?.Branch_Item_Object?.Branch_ID?.Branch_Name,
            };
          }
          if (curr.Variant_Colour) {
            if (
              !acc[curr.Variant_Size]["colours"][
                curr.Variant_Colour.Colour_Category
              ]
            ) {
              acc[curr.Variant_Size]["colours"][
                curr.Variant_Colour.Colour_Category
              ] = [];
            }
            acc[curr.Variant_Size]["colours"][
              curr.Variant_Colour.Colour_Category
            ].push({
              colour: curr.Variant_Colour.Colour_Name,
              price: cp ? curr.Variant_Cost_Price : curr.Variant_Selling_Price,
              id: curr.Variant_ID,
            });
          } else {
            acc[curr.Variant_Size]["colours"]["Unpainted"] = [
              {
                colour: "Unpainted",
                price: cp
                  ? curr.Variant_Cost_Price
                  : curr.Variant_Selling_Price,
                id: curr.Variant_ID,
              },
            ];
          }
          return acc;
        },
        {},
      ) || [],
    ).map(([key, value]) => ({ ...value, size: key })),
  });
  const [error, setError] = React.useState(false);
  const [variantSize, setVariantSize] = React.useState(item?.variantSize);
  const [variantId, setVariantId] = React.useState(item?.variantID);
  const [cuttingList, setCuttingList] = React.useState(item?.cutting || []);
  const [showModal, setShowModal] = React.useState(false);

  const supplierOptions =
    suppliers?.map((sup) => ({
      label: sup.Supplier_Name,
      value: sup._id.toString(),
    })) || [];

  const variantOptions = itemDetails.variants.map((record) => ({
    label: record.size,
    value: record.size,
  }));

  const selectedSize = itemDetails.variants.find(
    (item) => item.size === variantSize,
  );

  const colourCategoryMap = colourCategories?.reduce((acc, curr) => {
    acc[curr._id] = curr.Colour_Category_Name;
    return acc;
  }, {});

  const colourOptions = Object.entries(selectedSize?.colours || {}).reduce(
    (acc, curr) => {
      if (curr[1].length > 0) {
        const key =
          (colourCategoryMap && colourCategoryMap[curr[0]]) || curr[0];
        acc[key] = curr[1].map((record) => ({
          value: record.id,
          label: record.colour,
        }));
      }
      return acc;
    },
    {},
  );

  const selectedItem = variantId
    ? _.flatten(Object.values(selectedSize.colours)).find(
        (item) => item.id === variantId,
      )
    : undefined;

  const factor = variantId
    ? selectedSize.unit === "MM"
      ? 1000
      : selectedSize.unit === "CM"
      ? 100
      : 1
    : 1;

  async function onItemChange(id) {
    setLoading(true);
    let url = "";
    if (selectedDetails?.branch) {
      url += `${process.env.REACT_APP_BASE_URL}/api/item/getOneItemByBranch?itemID=${id}&branchID=${selectedDetails.branch}`;
    } else {
      url += `${process.env.REACT_APP_BASE_URL}/api/item/getOneItemByLocation?itemID=${id}&location=${location}`;
    }
    const response = await createGetRequest(url);
    if (!response.data) {
      setError(true);
    } else {
      const variants = Object.entries(
        response.data.Branch_Item_Variants.reduce((acc, curr) => {
          if (!acc[curr.Variant_Size]) {
            acc[curr.Variant_Size] = {
              colours: {},
              minSize: curr.Variant_Size_Min,
              maxSize: curr.Variant_Size_Max,
              type: curr.Variant_Size_Type,
              unit: curr.Variant_Size_Type_Units,
              branch: response.data.Branch_ID?.Branch_Name,
            };
          }
          if (curr.Variant_Colour) {
            if (
              !acc[curr.Variant_Size]["colours"][
                curr.Variant_Colour.Colour_Category._id
              ]
            ) {
              acc[curr.Variant_Size]["colours"][
                curr.Variant_Colour.Colour_Category._id
              ] = [];
            }
            acc[curr.Variant_Size]["colours"][
              curr.Variant_Colour.Colour_Category._id
            ].push({
              colour: curr.Variant_Colour.Colour_Name,
              price: cp ? curr.Variant_Cost_Price : curr.Variant_Selling_Price,
              id: curr.Variant_ID,
            });
          } else {
            acc[curr.Variant_Size]["colours"]["Unpainted"] = [
              {
                colour: "Unpainted",
                price: cp
                  ? curr.Variant_Cost_Price
                  : curr.Variant_Selling_Price,
                id: curr.Variant_ID,
              },
            ];
          }
          return acc;
        }, {}) || [],
      ).map(([key, value]) => ({ ...value, size: key }));
      setItemDetails({
        ...response.data,
        Branch_Item_Variants: response.data.Branch_Item_Variants.map((curr) => {
          if (curr.Variant_Colour) {
            return {
              ...curr,
              Variant_Colour: {
                ...curr.Variant_Colour,
                Colour_Category: curr.Variant_Colour.Colour_Category._id,
              },
            };
          } else {
            return curr;
          }
        }),
        variants,
      });
      if (variants.length === 1) {
        setVariantSize(variants[0].size);
        const colourOptions = _.flatten(Object.values(variants[0].colours));
        if (colourOptions.length === 1) {
          setVariantId(colourOptions[0].id);
        } else {
          setVariantId(undefined);
        }
      } else {
        setVariantSize(undefined);
      }
    }
    setLoading(false);
  }

  function discard() {
    setEditing(false);
    setSupplier(selectedDetails?.supplier || undefined);
    setItemDetails({ variants: [] });
    setVariantSize(undefined);
    setVariantId(undefined);
    setCuttingList([]);
    onDiscard();
  }

  function submit() {
    const colour = selectedItem;
    let qty;
    let totalPrice;
    let cutting;
    if (selectedSize.type === "Standard") {
      qty = parseInt(document.getElementById("quantity").value);
      if (isNaN(qty) || !qty) {
        alert("Item Quantity is required");
        return;
      }
      totalPrice = `$${(colour.price * qty).toFixed(2)}`;
    } else {
      if (cuttingList.length === 0) {
        alert("Cutting list cannot be empty");
        return;
      }
      cutting = cuttingList.map((record) => ({
        ...record,
        Total_Size_Price:
          (record.Size * record.Quantity * colour.price) / factor,
      }));
      totalPrice = `$${cutting
        .reduce((acc, curr) => acc + curr.Total_Size_Price, 0)
        .toFixed(2)}`;
    }

    const data = {
      supplier: supplierOptions.find((item) => item.value === supplier).label,
      branch: selectedSize.branch,
      name: `${itemDetails.Branch_Item.Item_Name}-${selectedSize.size}`,
      price: `$${colour.price.toFixed(2)}`,
      colour: colour.colour !== "Unpainted" ? colour.colour : "",
      quantity: parseInt(qty),
      itemID: itemDetails.Branch_Item.Item_ID,
      variantID: variantId,
      totalPrice,
      cutting,
      unit: selectedSize.unit,
      variantSize: selectedSize.size,
      raw: { Branch_Item_Object: itemDetails },
    };

    if (editedRow) {
      onEdit(data, parseInt(editedRow));
    } else {
      onAdd(data);
    }
    discard();
  }

  function toggleModal() {
    setShowModal((curr) => !curr);
  }

  return (
    <div>
      <div className={styles.buttonContainer}>
        <PrimaryButton
          variant="outlined"
          label="Add New Line"
          onClick={() => {
            setEditing(true);
          }}
          loading={editedRow}
        />
      </div>
      {(editedRow || editing) &&
        (error ? (
          <div>Something went wrong</div>
        ) : (
          <div id="edit">
            <div className={styles.editForm}>
              {!selectedDetails?.supplier && (
                <Dropdown
                  name="Supplier"
                  id="supplier"
                  label="Supplier"
                  required
                  options={supplierOptions}
                  placeholder="Select Supplier"
                  value={supplier}
                  fullWidth
                  onChange={(value) => {
                    setSupplier(value);
                    setItemDetails({ variants: [] });
                    setVariantSize(undefined);
                    setVariantId(undefined);
                  }}
                />
              )}
              {supplier && (
                <Autocomplete
                  id="Item"
                  name="Item"
                  fullWidth
                  label="Item"
                  required
                  url={
                    location
                      ? `${process.env.REACT_APP_BASE_URL}/api/item/getManyItems?limit=10&supplier=${supplier}&location=${location}`
                      : `${process.env.REACT_APP_BASE_URL}/api/item/getManyItems?limit=10&supplier=${supplier}&branch=${selectedDetails?.branch}`
                  }
                  callback={onItemChange}
                  key={supplier}
                  disabled={loading}
                  initialValue={itemDetails?.Branch_Item?.Item_Name}
                  formatter={formatter}
                />
              )}
              {itemDetails?.Branch_Item?.Item_ID && (
                <Dropdown
                  name="Size/ Variant"
                  id="variant"
                  label="Variant"
                  required
                  options={variantOptions}
                  placeholder="Select Variant"
                  value={variantSize}
                  fullWidth
                  onChange={(size) => {
                    setVariantSize(size);
                    setVariantId(undefined);
                  }}
                  key={itemDetails?.Branch_Item?.Item_ID}
                />
              )}
              {variantSize && (
                <>
                  {!selectedDetails?.branch && (
                    <Control
                      label="Supplier Branch"
                      id="supplier-name"
                      name="Supplier_Branch"
                      editable={false}
                      fullWidth
                      initialValue={selectedSize?.branch}
                      key={supplier}
                    />
                  )}
                  <DisplayDropdown
                    name="Colour"
                    id="colour"
                    label="Colour"
                    required
                    options={colourOptions}
                    placeholder="Select Colour"
                    value={variantId}
                    fullWidth
                    onChange={setVariantId}
                    key={variantSize}
                  />
                  {selectedSize.type === "Standard" ? (
                    <Control
                      label="Quantity"
                      id="quantity"
                      name="Quantity"
                      fullWidth
                      initialValue={parseInt(item?.quantity || "1")}
                      type="number"
                    />
                  ) : (
                    <div className={styles.cuttingListContainer}>
                      <div className={styles.cuttingListHeader}>
                        Cutting List
                      </div>
                      {cuttingList.length > 0 ? (
                        <div className={styles.clist}>
                          {cuttingList.map((record, index) => (
                            <div key={index.toString()}>
                              {record.Quantity} X {record.Size}
                              {selectedSize.unit}
                            </div>
                          ))}
                          <button
                            className={`${styles.poButton} ${styles.action}`}
                            type="button"
                            onClick={toggleModal}
                          >
                            Edit
                          </button>
                        </div>
                      ) : (
                        <button
                          className={`${styles.poButton} ${styles.action}`}
                          type="button"
                          onClick={toggleModal}
                        >
                          Create List
                        </button>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
            {variantId && (
              <div className={styles.editButtonsContainer}>
                <PrimaryButton label="Save" type="button" onClick={submit} />
                <PrimaryButton
                  label="Discard"
                  type="button"
                  variant="outlined"
                  onClick={discard}
                />
                {editedRow && (
                  <span
                    className={styles.delete}
                    onClick={() => {
                      onDelete(parseInt(editedRow));
                      onDiscard();
                    }}
                  >
                    Delete Item
                  </span>
                )}
              </div>
            )}
          </div>
        ))}
      <CuttingList
        showModal={showModal}
        initialList={cuttingList}
        itemName={itemDetails?.Branch_Item?.Item_Name}
        variantSize={variantSize}
        toggleModal={toggleModal}
        unit={selectedSize?.unit}
        price={selectedItem?.price}
        factor={factor}
        maxSize={selectedSize?.maxSize}
        minSize={selectedSize?.minSize}
        updateCuttingList={setCuttingList}
      />
    </div>
  );
}
